@use 'sass:math';

@import './variables';
@import './mixins';

html, .root {
    font-family: $res-body-font-family;
    font-size: $res-font-size-base;
    line-height: $res-line-height;
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    height: 100%;
    margin: 0;
    padding: 0;
}

strong {
    font-weight: bolder;
}

*, *::before, *::after {
    box-sizing: border-box;
}

.res-container{
    width: auto;
    @include standard-margins($res-grid-gutter);
}

.res-row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 0;
    margin-right: math.div(-1 * $res-grid-gutter, 2);
    margin-bottom: 0;
    margin-left: math.div(-1 * $res-grid-gutter, 2);
}

.res-col{
    flex-grow: 1;
    flex-basis: 0;
    @include standard-margins($res-grid-gutter);
}

.res-col-auto {
    width: auto;
    flex-grow: 0;
    flex-shrink: 1;
    @include standard-margins($res-grid-gutter);
}

.res-no-gutter {
    @include standard-margins($res-grid-gutter * -1);
}

.res-content-flush {
    margin-left: $res-grid-gutter * -1.5;
    margin-right: $res-grid-gutter * -1;
    // @include standard-margins($res-grid-gutter * -2);
}

@for $column-idx from 1 through $res-column-count {
    .res-col-#{$column-idx} {
        $max-width: percentage(math.div($column-idx, $res-column-count));
        @include column-base($max-width, $res-grid-gutter);
    }
}


@each $name, $size in $res-responsive-breakpoints {

    @for $column-idx from 1 through $res-column-count {

        @include breakpoint-up($name, $res-responsive-breakpoints) {

            .res-col-#{$name}-#{$column-idx} {
                $max-width: percentage(math.div($column-idx, $res-column-count));
                @include column-base($max-width, $res-grid-gutter);
            }
        }
    }
}

$res-font-size-base: 16px;                  // 1 em/rem;

$res-grid-base: .5rem;                      // 8px - Basis for the entire system
$res-grid-gutter: $res-grid-base * 2;       // This will actually result in 16px gutters between columns

$res-line-height: 1.5rem;
$res-padding-default: .5rem;

$res-column-count: 12;

// Universal Gradient Settings
$res-gradient-angle: 135deg;
$res-gradient-start: 33%;
$res-gradient-end: 100%;
$res-gradient-shift: 10%;

/* Responsive Breakpoints */
/* These align with the boostrap breakpoints */
$res-responsive-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
);

$res-heading-font-family: 'Gilroy';
$res-body-font-family: 'Gilroy';
$res-alternate-font-family: 'Helvetica Neue';

$res-animation-speed: .25s;

// Export as CSS variables
:root {
    --res-font-size-base: #{$res-font-size-base};
    --res-grid-base: #{$res-grid-base};
    --res-grid-gutter: #{$res-grid-gutter};
    --res-line-height: #{$res-line-height};
    --res-padding-default: #{$res-padding-default};
    --res-column-count: #{$res-column-count};

    --res-heading-font-family: #{$res-heading-font-family};
    --res-body-font-family: #{$res-body-font-family};
    --res-alternate-font-family: #{$res-alternate-font-family};

    --res-gradient-angle: #{$res-gradient-angle};
    --res-gradient-start: #{$res-gradient-start};
    --res-gradient-end: #{$res-gradient-end};
    --res-gradient-shift: #{$res-gradient-shift};

    --res-animation-speed: #{$res-animation-speed};
}

@import './responsive';

@mixin column-base($width, $gutter){

    width: calc($width - $gutter);    // Incompatible Units in Sass
    flex-grow: 0;
    flex-shrink: 0;
    @include standard-margins($gutter);
}

@mixin containerize(){
    @each $breakpoint, $max-width in $container-max-widths {
        @include breakpoint-up($breakpoint){
            margin-right: auto;
            margin-left: auto;
            max-width: $max-width;
        }
    }
}

@mixin standard-margins($gutter){
    margin-top: 0;
    margin-bottom: 0;
    margin-left: math.div($gutter, 2);
    margin-right: math.div($gutter, 2);
}

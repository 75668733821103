$res-alfalfa: "\e900";
$res-be-curious: "\e901";
$res-be-upstanding: "\e902";
$res-build-together: "\e903";
$res-canola: "\e910";
$res-cereal: "\e911";
$res-citrus: "\e912";
$res-corn: "\e913";
$res-cotton: "\e915";
$res-crop-protection-1: "\e916";
$res-crop-protection-2: "\e917";
$res-crop-protection-3: "\e91e";
$res-crop-protection: "\e91f";
$res-development: "\e920";
$res-digital-agriculture: "\e924";
$res-discovery: "\e925";
$res-enrich-lives: "\e926";
$res-extend-improve: "\e927";
$res-food-security: "\e92c";
$res-forest: "\e92d";
$res-fruit-vegetables: "\e930";
$res-fruit: "\e931";
$res-global: "\e93e";
$res-grasses: "\e93f";
$res-heart: "\e940";
$res-innovation: "\e941";
$res-inoculants: "\e942";
$res-insects: "\e943";
$res-launch: "\e944";
$res-live-safely: "\e946";
$res-nature: "\e947";
$res-operate-existing: "\e948";
$res-precision: "\e949";
$res-pre-launch: "\e94a";
$res-rampup: "\e94b";
$res-rice: "\e94c";
$res-row-crops: "\e94d";
$res-seed-development: "\e94e";
$res-seeds: "\e94f";
$res-silage: "\e950";
$res-sorghum: "\e951";
$res-soybean: "\e952";
$res-stand-tall: "\e953";
$res-sugar-cane: "\e954";
$res-sunflower: "\e955";
$res-tree-nuts: "\e956";
$res-vegetables: "\e957";
$res-vine-nuts: "\e975";
$res-vine: "\e976";
$res-wheat: "\e977";

.res-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Corteva Icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 4rem;
  font-size: 4rem;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.res-icon-small {
  line-height: 2rem;
  font-size: 2rem;
}

.res-icon-large {
  line-height: 6rem;
  font-size: 6rem;
}

.res-alfalfa {
  &:before {
    content: $res-alfalfa; 
  }
}
.res-be-curious {
  &:before {
    content: $res-be-curious; 
  }
}
.res-be-upstanding {
  &:before {
    content: $res-be-upstanding; 
  }
}
.res-build-together {
  &:before {
    content: $res-build-together; 
  }
}
.res-canola {
  &:before {
    content: $res-canola; 
  }
}
.res-cereal {
  &:before {
    content: $res-cereal; 
  }
}
.res-citrus {
  &:before {
    content: $res-citrus; 
  }
}
.res-corn {
  &:before {
    content: $res-corn; 
  }
}
.res-cotton {
  &:before {
    content: $res-cotton; 
  }
}
.res-crop-protection-1 {
  &:before {
    content: $res-crop-protection-1; 
  }
}
.res-crop-protection-2 {
  &:before {
    content: $res-crop-protection-2; 
  }
}
.res-crop-protection-3 {
  &:before {
    content: $res-crop-protection-3; 
  }
}
.res-crop-protection {
  &:before {
    content: $res-crop-protection; 
  }
}
.res-development {
  &:before {
    content: $res-development; 
  }
}
.res-digital-agriculture {
  &:before {
    content: $res-digital-agriculture; 
  }
}
.res-discovery {
  &:before {
    content: $res-discovery; 
  }
}
.res-enrich-lives {
  &:before {
    content: $res-enrich-lives; 
  }
}
.res-extend-improve {
  &:before {
    content: $res-extend-improve; 
  }
}
.res-food-security {
  &:before {
    content: $res-food-security; 
  }
}
.res-forest {
  &:before {
    content: $res-forest; 
  }
}
.res-fruit-vegetables {
  &:before {
    content: $res-fruit-vegetables; 
  }
}
.res-fruit {
  &:before {
    content: $res-fruit; 
  }
}
.res-global {
  &:before {
    content: $res-global; 
  }
}
.res-grasses {
  &:before {
    content: $res-grasses; 
  }
}
.res-heart {
  &:before {
    content: $res-heart; 
  }
}
.res-innovation {
  &:before {
    content: $res-innovation; 
  }
}
.res-inoculants {
  &:before {
    content: $res-inoculants; 
  }
}
.res-insects {
  &:before {
    content: $res-insects; 
  }
}
.res-launch {
  &:before {
    content: $res-launch; 
  }
}
.res-live-safely {
  &:before {
    content: $res-live-safely; 
  }
}
.res-nature {
  &:before {
    content: $res-nature; 
  }
}
.res-operate-existing {
  &:before {
    content: $res-operate-existing; 
  }
}
.res-precision {
  &:before {
    content: $res-precision; 
  }
}
.res-pre-launch {
  &:before {
    content: $res-pre-launch; 
  }
}
.res-rampup {
  &:before {
    content: $res-rampup; 
  }
}
.res-rice {
  &:before {
    content: $res-rice; 
  }
}
.res-row-crops {
  &:before {
    content: $res-row-crops; 
  }
}
.res-seed-development {
  &:before {
    content: $res-seed-development; 
  }
}
.res-seeds {
  &:before {
    content: $res-seeds; 
  }
}
.res-silage {
  &:before {
    content: $res-silage; 
  }
}
.res-sorghum {
  &:before {
    content: $res-sorghum; 
  }
}
.res-soybean {
  &:before {
    content: $res-soybean; 
  }
}
.res-stand-tall {
  &:before {
    content: $res-stand-tall; 
  }
}
.res-sugar-cane {
  &:before {
    content: $res-sugar-cane; 
  }
}
.res-sunflower {
  &:before {
    content: $res-sunflower; 
  }
}
.res-tree-nuts {
  &:before {
    content: $res-tree-nuts; 
  }
}
.res-vegetables {
  &:before {
    content: $res-vegetables; 
  }
}
.res-vine-nuts {
  &:before {
    content: $res-vine-nuts; 
  }
}
.res-vine {
  &:before {
    content: $res-vine; 
  }
}
.res-wheat {
  &:before {
    content: $res-wheat; 
  }
}
  
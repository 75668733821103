@font-face {
    font-family: 'Corteva Icons';
    src:  url(corteva-icons/corteva-icons.eot);
    src:  url(corteva-icons/corteva-icons.eot#iefix) format('embedded-opentype'),
          url(corteva-icons/corteva-icons.ttf) format('truetype'),
          url(corteva-icons/corteva-icons.woff) format('woff'),
          url(corteva-icons/corteva-icons.svg#corteva-icons) format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Gilroy';
    src: url(gilroy/gilroy-extrabolditalic.eot);
    src: url(gilroy/gilroy-extrabolditalic.eot?#iefix) format('embedded-opentype'),
         url(gilroy/gilroy-extrabolditalic.woff2) format('woff2'),
         url(gilroy/gilroy-extrabolditalic.woff) format('woff'),
         url(gilroy/gilroy-extrabolditalic.ttf) format('truetype'),
         url(gilroy/gilroy-extrabolditalic.svg#gilroy-extrabolditalic) format('svg');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(gilroy/gilroy-medium.eot);
    src: url(gilroy/gilroy-medium.eot?#iefix) format('embedded-opentype'),
         url(gilroy/gilroy-medium.woff2) format('woff2'),
         url(gilroy/gilroy-medium.woff) format('woff'),
         url(gilroy/gilroy-medium.ttf) format('truetype'),
         url(gilroy/gilroy-medium.svg#gilroy-medium) format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(gilroy/gilroy-extrabold.eot);
    src: url(gilroy/gilroy-extrabold.eot?#iefix) format('embedded-opentype'),
         url(gilroy/gilroy-extrabold.woff2) format('woff2'),
         url(gilroy/gilroy-extrabold.woff) format('woff'),
         url(gilroy/gilroy-extrabold.ttf) format('truetype'),
         url(gilroy/gilroy-extrabold.svg#gilroy-extrabold) format('svg');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(gilroy/gilroy-lightitalic.eot);
    src: url(gilroy/gilroy-lightitalic.eot?#iefix) format('embedded-opentype'),
         url(gilroy/gilroy-lightitalic.woff2) format('woff2'),
         url(gilroy/gilroy-lightitalic.woff) format('woff'),
         url(gilroy/gilroy-lightitalic.ttf) format('truetype'),
         url(gilroy/gilroy-lightitalic.svg#gilroy-lightitalic) format('svg');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(gilroy/gilroy-light.eot);
    src: url(gilroy/gilroy-light.eot?#iefix) format('embedded-opentype'),
         url(gilroy/gilroy-light.woff2) format('woff2'),
         url(gilroy/gilroy-light.woff) format('woff'),
         url(gilroy/gilroy-light.ttf) format('truetype'),
         url(gilroy/gilroy-light.svg#gilroy-light) format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(gilroy/gilroy-mediumitalic.eot);
    src: url(gilroy/gilroy-mediumitalic.eot?#iefix) format('embedded-opentype'),
         url(gilroy/gilroy-mediumitalic.woff2) format('woff2'),
         url(gilroy/gilroy-mediumitalic.woff) format('woff'),
         url(gilroy/gilroy-mediumitalic.ttf) format('truetype'),
         url(gilroy/gilroy-mediumitalic.svg#gilroy-mediumitalic) format('svg');
    font-weight: 500;
    font-style: italic;
}

@font-face{
    font-family: 'Helvetica Neue';
    src: url(neue-helvetica/neue-helvetica-light.eot);
    src: url(neue-helvetica/neue-helvetica-light.eot?#iefix) format('embedded-opentype'),
         url(neue-helvetica/neue-helvetica-light.woff2) format('woff2'),
         url(neue-helvetica/neue-helvetica-light.woff) format('woff'),
         url(neue-helvetica/neue-helvetica-light.ttf) format('truetype'),
         url(neue-helvetica/neue-helvetica-light.svg#HelveticaNeueLTW04-45Light) format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face{
    font-family: 'Helvetica Neue';
    src: url(neue-helvetica/neue-helvetica-medium.eot);
    src: url(neue-helvetica/neue-helvetica-medium.eot?#iefix) format('embedded-opentype'),
         url(neue-helvetica/neue-helvetica-medium.woff2) format('woff2'),
         url(neue-helvetica/neue-helvetica-medium.woff) format('woff'),
         url(neue-helvetica/neue-helvetica-medium.ttf) format('truetype'),
         url(neue-helvetica/neue-helvetica-medium.svg#HelveticaNeueLTW05-65Medium) format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face{
    font-family: 'Helvetica Neue';
    src: url(neue-helvetica/neue-helvetica-heavy.eot);
    src: url(neue-helvetica/neue-helvetica-heavy.eot?#iefix) format('embedded-opentype'),
         url(neue-helvetica/neue-helvetica-heavy.woff2) format('woff2'),
         url(neue-helvetica/neue-helvetica-heavy.woff) format('woff'),
         url(neue-helvetica/neue-helvetica-heavy.ttf) format('truetype'),
         url(neue-helvetica/neue-helvetica-heavy.svg#HelveticaNeueLTW05-85Heavy) format('svg');
    font-weight: 700;
    font-style: normal;
}

@import '../colors';
@import '../variables';

.theme-light {
    --theme-grid-base: #{$res-grid-base};
    --theme-padding: #{$res-padding-default};
    --theme-font-family: #{$res-heading-font-family};
    --theme-font-size-base: #{$res-font-size-base};

    --theme-color-background-raw: 255, 255, 255;

    --theme-color-background: rgba(var(--theme-color-background-raw), 1);
    --theme-color-text: #{$res-color-black};
    --theme-color-hero: #{$res-color-hero};

    --theme-heading-font-family: #{$res-heading-font-family};
   
    --theme-footer-color-background: #{$res-color-hero};
    --theme-footer-color-text: #{$res-color-white};
    --theme-footer-color-hero: #{$res-color-white};
    --theme-footer-color-app-details: #{mix($res-color-white, $res-color-hero)};

    $res-theme-hub-gradient-color: $res-color-hero;
    --theme-hub-color-gradient-start: #{$res-theme-hub-gradient-color};
    --theme-hub-color-gradient-end: #{darken($res-theme-hub-gradient-color, $res-gradient-shift)};

    $res-theme-hub-color-bg-color: $res-color-white;
    --theme-hub-color-background-gradient-start: #{$res-theme-hub-color-bg-color};
    --theme-hub-color-background-gradient-end: #{darken($res-theme-hub-color-bg-color, $res-gradient-shift)};

    --theme-scroll-color: var(--res-color-white);
    --theme-scroll-color-background-gradient-start: var(--res-color-hero);
    --theme-scroll-color-background-gradient-end: #{darken($res-color-hero, $res-gradient-shift)};

    --theme-layout-background-back:  linear-gradient(var(--res-gradient-angle), rgba(255,255,255, 0%) 40%, rgba(0,114,206, 20%) 100%);
    --theme-layout-background-middle: none;
    --theme-layout-background-front: none;

    --theme-layout-mobile-menu-backdrop-filter: blur(5px);

    --theme-avatar-color: var(--res-color-hero);

    .res-btn {
        box-shadow: 3px 3px 3px var(--res-color-gray-medium);
    }

    .res-btn-secondary {
        background-color: var(--res-color-dark-background);
        border-color: var(--res-color-dark-background);
    
        background: linear-gradient($res-gradient-angle, $res-color-dark-background $res-gradient-start, darken($res-color-dark-background, $res-gradient-shift) $res-gradient-end);    
    }
}
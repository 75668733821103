body, .article {
    font-family: $res-heading-font-family;
    font-size: 1rem;         // 16px;
    font-weight: 500;
    font-style: normal;
    line-height: 24px;
    margin: auto;
}

p, ul, ol, pre, table, blockquote {
    margin-top: 0;
    margin-bottom: 1.5rem;
}
  
ul ul, ol ol, ul ol, ol ul {
    margin-top: 0;
    margin-bottom: 0;
}
    
/* Let's make sure all's aligned */
$res-hr-size: calc(0.0625rem / 2);
hr, .hr {
    border-top: $res-hr-size solid;
    border-bottom: none;
    margin-top: calc(.5rem - #{$res-hr-size});
    margin-bottom: .5rem;
}
  
a, b, i, strong, em, small, code {
    line-height: 24px;
}

sub, sup {
    line-height: 24px;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -0.5rem;
}

sub {
    bottom: -0.25rem;
}
@import './variables';
@import './mixins';

/* All sizes based on 16px = 1rem base font size */

h1, .res-heading1 {
  font-family: $res-heading-font-family;
  font-weight: 800;
  font-style: normal;
  font-size: 2.5rem;          // 40px
  line-height: 3rem;          // 48px
  @include tracking(-5);
  margin-top: 1rem;
  margin-bottom: 1rem;
}

h2, .res-heading2 {
  font-family: $res-heading-font-family;  
  font-weight: 500;
  font-style: normal;	
  font-size: 2rem;          // 32px
  line-height: 2.5rem;      // 40px
  @include tracking(-5);
  margin-top: 1rem;
  margin-bottom: 1rem;
}

h3, .res-heading3 {
  font-family: $res-heading-font-family; 
  font-weight: 300;
  font-style: italic;
  font-size: 1.625rem;      // 26px 
  line-height: 2.5rem;      // 40px
  @include tracking(-5);
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

h4, .res-subheading1 {
  font-family: $res-heading-font-family;  
  font-weight: 500;
  font-style: normal;	
  font-size: 1.375rem;          // 22px
  line-height: 1.5rem;
  @include tracking(-5);
  margin-top: 1rem;
  margin-bottom: 0.5rem;

  &.res-subheading-alt {
    color: $res-color-hero;
  }
}

h5, .res-subheading2 {
  font-family: $res-heading-font-family;
  font-weight: 800;
  font-style: normal;
  font-size: 1.25rem;        // 20px
  line-height: 1.5rem;
  @include tracking(50);
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;

  &.res-subheading-alt {
    color: $res-color-hero;
  }
}

h6, .res-subheading3 {
  font-family: $res-heading-font-family;
  font-weight: 800;
  font-style: normal;
  font-size: 1.125rem;     // 18px
  line-height: 1.5rem;
  @include tracking(5);
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
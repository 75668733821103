@import 'variables';

/* Corporate Colors */
// https://brandcenter.corteva.com/content/index/guid/color_palette

/* Primary Palette */
/* Blue is our hero color and should be used for all logo applications during our introductory period. Layouts should default to the use of blue. */
$res-color-hero: #0072ce;
$res-color-blue: $res-color-hero;

/* Neutral Palette */
/* A set of neutral tones is also provided. Black, light gray and white help to balance the intensity of the primary palette and serve to display 
more functional elements like copy. */
$res-color-black: #000;
$res-color-gray-light: #e6e8ea;
$res-color-white: #fff;

/* Range of grays between color-light-gray and color-black */
/* Used the following tool to generate the colors in between
/* https://hihayk.github.io/scale/#9/0/100/80/-51/67/20/14/e6e8ea/230/232/234/white */
$res-color-gray-100: $res-color-gray-light;
$res-color-gray-200: #CCCED0;
$res-color-gray-300: #B3B4B6;
$res-color-gray-400: #999A9C;
$res-color-gray-500: #808082;
$res-color-gray-600: #666668;
$res-color-gray-700: #4D4D4E;
$res-color-gray-800: #333334;
$res-color-gray-900: #1A1A1A;

$res-color-dark-background: #2E3941;            // This is a blend of dark gray (#666666) and corteva blue

/* Secondary Palette */
/* The secondary colors green and orange (but NOT the multiple light and dark shades) may be used in layouts in support of the blue.
There should be a 80%-90% blue presence relative to a 10%-20% accent color presence for all applications (marketing materials, collateral, etc.)*/
$res-color-green: #00bf6f;
$res-color-orange: #fc4c02;

/* Web/Animation Palette */
/* For digital applications, there are instances where a design system will have to take on more content and interaction states. For these scenarios, 
a tertiary palette of additional light and dark shades is available. These will be used in addition to the secondary palette.

Animation or illustration may also require these additional shades and can be used for that purpose. Animation and illustration work should only be 
initiated by the lead agency.*/

// We are not using the dark versions of these colors b/c it will provide too many options


// $res-color-blue defined above
$res-color-blue-medium: #459aff;
$res-color-blue-light:  #88c9ff;

// $color-green defined above
$res-color-green-medium: #6ffca2;
$res-color-green-light: #aeffcf;

// $color-orange defined above
$res-color-orange-medium: #FF8352;
$res-color-orange-light: #ffac88;

/* Additional Contextual Colors */
/* These were created using the offical blue, green, and orange colors as the base palette.  These shouldn't be used in the general design, but instead
to convey some specific meaning - or used to provide variety in things like charts */

$res-color-red: #d33f49;
$res-color-red-medium: #d46c73;
$res-color-red-light: #d48a8f;

$res-color-yellow: #f3b700;
$res-color-yellow-medium: #f2c849;
$res-color-yellow-light: #f2d479;

$res-color-teal: #0ebdcb;
$res-color-teal-medium: #47c3cc;
$res-color-teal-light: #7ac7cc;

$res-color-cyan: #1be7ff;
$res-color-cyan-medium: #66f0ff;
$res-color-cyan-light: #99f5ff;

$res-color-indigo:#0a2342 ;
$res-color-indigo-medium: #213042;
$res-color-indigo-light: #2e3742;

$res-color-violet: #46237a;
$res-color-violet-medium: #563d7a;
$res-color-violet-light: #65567a;

$res-color-pink: #c200fb;
$res-color-pink-medium: #d14bfa;
$res-color-pink-light: #dd7dfa;

// Some gradient colors


// Export as CSS Variables
:root {
    --res-color-hero: #{$res-color-hero};

    --res-color-white: #{$res-color-white};
    --res-color-gray-light: #{$res-color-gray-light};
    --res-color-gray-medium: #{$res-color-gray-200};
    --res-color-black: #{$res-color-black};  
    
    --res-color-blue: #{$res-color-blue};
    --res-color-blue-medium: #{$res-color-blue-medium};
    --res-color-blue-light: #{$res-color-blue-light};

    --res-color-green: #{$res-color-green};
    --res-color-green-medium: #{$res-color-green-medium};
    --res-color-green-light: #{$res-color-green-light};

    --res-color-orange: #{$res-color-orange};
    --res-color-orange-medium: #{$res-color-orange-medium};
    --res-color-orange-light: #{$res-color-orange-light};
    
    --res-color-red: #{$res-color-red};
    --res-color-red-medium: #{$res-color-red-medium};
    --res-color-red-light: #{$res-color-red-light};
    
    --res-color-yellow: #{$res-color-yellow};
    --res-color-yellow-medium: #{$res-color-yellow-medium};
    --res-color-yellow-light: #{$res-color-yellow-light};
    
    --res-color-teal: #{$res-color-teal};
    --res-color-teal-medium: #{$res-color-teal-medium};
    --res-color-teal-light: #{$res-color-teal-light};
    
    --res-color-cyan: #{$res-color-cyan};
    --res-color-cyan-medium: #{$res-color-cyan-medium};
    --res-color-cyan-light: #{$res-color-cyan-light};
    
    --res-color-indigo: #{$res-color-indigo};
    --res-color-indigo-medium: #{$res-color-indigo-medium};
    --res-color-indigo-light: #{$res-color-indigo-light};
    
    --res-color-violet: #{$res-color-violet};
    --res-color-violet-medium: #{$res-color-violet-medium};
    --res-color-violet-light: #{$res-color-violet-light};

    --res-color-pink: #{$res-color-pink};
    --res-color-pink-medium: #{$res-color-pink-medium};
    --res-color-pink-light: #{$res-color-pink-light};

    --res-color-dark-background: #{$res-color-dark-background};


    // Gradient targets
    --res-color-white-gradient-darker: #{darken($res-color-white, $res-gradient-shift)};
    --res-color-hero-gradient-darker: #{darken($res-color-hero, $res-gradient-shift)};
    --res-color-black-gradient-lighter: #{lighten($res-color-black, $res-gradient-shift)};
}
@import './util';

@function breakpoint-min($name, $breakpoints){
    $min: map-get($breakpoints, $name);
    @return if($min != 0, $min, 0);
}

@function breakpoint-max($name, $breakpoints) {

    $nextKey: map-next($name, $breakpoints);
    @return if($nextKey, breakpoint-min($nextKey, $breakpoints) - .02, null);
}

@mixin breakpoint-up($name, $breakpoints){

    $min: breakpoint-min($name, $breakpoints);

    @if $min {
        @media (min-width: $min){
            @content;
        }
    } @else {
        @content;
    }
}

@mixin breakpoint-down($name, $breakpoints){
    $max: breakpoint-max($name, $breakpoints);

    @if $max {
        @media (max-width: $max) {
            @content;
        }
    } @else {
        @content; 
    }
}
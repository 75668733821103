@use 'sass:math';

@import './colors';



$tb-padding: .7rem;
$lr-padding: 1rem;

$border-width: .1875rem;
$border-radius: 3px;

$btn-gradient-darken: math.div($res-gradient-shift, 2);

@mixin button-style($btnColor) {
    background-color: $btnColor;
    border-color: $btnColor;
    background: linear-gradient($res-gradient-angle, $btnColor $res-gradient-start, darken($btnColor, $btn-gradient-darken) $res-gradient-end);
}

.res-btn {
    background-color: white;
    border-radius: $border-radius;
    border-width: 0;
    color: white;
    font-family: 'Gilroy';
    font-weight: 500;
    font-size: .875rem;
    padding: $tb-padding $lr-padding;
    line-height: .875rem;
    border-style: solid;
    cursor: pointer;

    transition: 250ms;

    &:hover {
        transform: scale(1.1);
    }
}

.res-btn-small {

    $sm-tb-padding: math.div($tb-padding, 1.75);
    $sm-lr-padding: math.div($lr-padding, 1.75);

    padding: $sm-tb-padding $sm-lr-padding !important;

    line-height: .75rem;
    font-size: .75rem;

    &.res-btn-outline {

        $outline-sm-tb-padding: $sm-tb-padding - $border-width;
        $outline-sm-lr-padding: $sm-lr-padding - $border-width;

        padding: $outline-sm-tb-padding $outline-sm-lr-padding !important;
    }
}

.res-btn-outline {

    $outline-tb-padding: $tb-padding - $border-width;
    $outline-lr-padding: $lr-padding - $border-width;

    color: #2E3941;
    background-color: white !important;
    border-width: $border-width;
    border-radius: $border-radius;
    padding: $outline-tb-padding $outline-lr-padding;

    background: linear-gradient($res-gradient-angle, $res-color-white $res-gradient-start, darken($res-color-white, $btn-gradient-darken) $res-gradient-end) !important;
}


.res-btn-primary {
    @include button-style($res-color-hero);
}

.res-btn-success {
    @include button-style($res-color-green);
}

.res-btn-danger {
    @include button-style($res-color-red);
}

.res-btn-warning {
    @include button-style($res-color-yellow);
}

.res-btn-info {
    @include button-style($res-color-teal);
}
